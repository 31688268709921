<template>
  <div class="about">
    <div style="  background:#142d4a;">
      <div class="common-width flex">
    <div class="left">
      <p class="left-title title-top">科学智能平台  <span style="font-size:16px;color:#ccc">（CG-AI4S）</span></p>
      <p class="left-title title-bottom" >AI与HPC融合计算平台</p>
      <p style="margin:20px 0;">CG-AI4S是一个旨在为基于AI及HPC的科学研究与应用实践提供算力、辅助工具、运行环境支撑的一体化智能平台，方便广大科研工作者（右侧需要重新出图）</p>
      <el-button type="warning" round size="small">立即前往<i class="el-icon-right"></i></el-button>
    </div>
    <div><img src="https://static.fastonetech.com/website/pc/assets/img/new-index/new-index-banner01.png" width="500px"/></div>
    
  </div>
  <div class="common-width"> 
    <el-carousel :interval="4000" type="card" height="200px">
    <el-carousel-item v-for="(item,index) in cardAry" :key="index">
     <img :src="item.path"/>
    </el-carousel-item>
  </el-carousel></div></div>
  
<div style="    padding: 60px 0;background: rgb(241 241 241);">
  <div class="common-width" style="margin-bottom:60px;" >
    <el-row :gutter="12">
  <el-col :span="5">
    <el-card shadow="hover">
      科学研究
    </el-card>
  </el-col>
  <el-col :span="5">
    <el-card shadow="hover">
      科学计算
    </el-card>
    
  </el-col>
  <el-col :span="5">
    <el-card shadow="hover">
      仿真模拟
    </el-card>
    
  </el-col>
  <el-col :span="5">
    <el-card shadow="hover">
      技能培训
    </el-card>
    
  </el-col><el-col :span="4">
    <el-card shadow="hover">
      商业智能
    </el-card>
    
  </el-col>

</el-row>
</div>
<div class="common-width letfRight" >

  <div><img src="https://eval.t.educg.com/img/shujuku000.95e9541d.png" width="500px" style="background:#ff8641"/></div>
  <div>
    <p>AI + HPC 一站式解决方案——AI及HPC任务协同调度、相互促进</p>
    <p>丰富的Web开发环境——开发可随时随地进行</p>
    <p>云桌面、低代码拖拉拽流程建模、Notebook、Web IDE</p>
    <p>专业的辅助工具——赋能复杂场景研究、提升工作效率</p>
    <p>支持团队协作——众人拾柴火焰高</p>
    <p>智能化资源调度——强大、灵活的资源管理能力</p>
    
    
  </div>
</div>
<div class="common-width letfRight" >

<div>
  <p>AI + HPC 一站式解决方案——AI及HPC任务协同调度、相互促进</p>
  <p>丰富的Web开发环境——开发可随时随地进行</p>
  <p>云桌面、低代码拖拉拽流程建模、Notebook、Web IDE</p>
  <p>专业的辅助工具——赋能复杂场景研究、提升工作效率</p>
  <p>支持团队协作——众人拾柴火焰高</p>
  <p>智能化资源调度——强大、灵活的资源管理能力</p>
  
  
</div>
<div><img src="https://eval.t.educg.com/img/shujuku000.95e9541d.png" width="500px" style="background:#a8a9aa"/></div>

</div>
  </div>
  <div class="bottom-bg">
    <div class="common-width" >看内容板块占位/footer</div>
  </div>

</div>


</template>
<script>
export default {
  data(){
    return{
      cardAry:[
        {path:'https://pic.616pic.com/bg_w1180/00/04/85/XF2Z0ajzVe.jpg'},
        {path:'https://pic.616pic.com/bg_w1180/00/06/69/2WEVkKOFPQ.jpg'},
        {path:'https://pic.616pic.com/bg_w1180/00/23/24/HlSZvwd1yb.jpg'}
      ]
      
    }
  }
}
</script>
<style scoped>
.about{

  width:100%;
  /* height:100vh; */
  /* margin-top:60px; */
  
}
.flex{
  display:flex;
  justify-content: space-between;
  background:#142d4a;
}
.left{
  padding:115px 10px 20px;
  text-align: left;
}
.letfRight{
  display:flex;
  justify-content: space-between;
  margin-top:20px;
  color:#313131;
  /* font-weight: bold; */
  line-height:50px;
  text-align: left;
}
.left-title{
  font-size: 22px;
    line-height: 41px;
  
}
.title-top{
  margin-bottom:35px;
}
.title-bottom{
  font-size:30px;
}
.bottom-bg{
  height:400px;
  background:rgb(20, 45, 74)
}
</style>
