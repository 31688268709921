<template>
    <div class="box">
        <div class="hello common-width">
    <p><img src="https://www.educg.net/images/cgicon.png" width="50px" style="margin-top: 5px;margin-right:40px;"/></p>
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
   

      
    >
      <el-menu-item index="1">首页</el-menu-item>
   
      <!-- <el-menu-item index="2">ioio</el-menu-item>

      <el-menu-item index="3">消息中心</el-menu-item>
      <el-menu-item index="4"></el-menu-item> -->
    </el-menu>
  </div>
    </div>

</template>
  
  <script>
export default {
  name: "heade",
  data() {
    return {
      activeIndex: "1",
      activeIndex2: "1",
    };
  },
  methods:{
    handleSelect(){}
  }
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
  .box{   
      background: #fff;
    position:sticky;
    top:0;
    z-index: 100;
}
  .hello{
    display: flex;

    // justify-content: space-around;
    // justify-content: space-between;
  }
</style>
  