<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <headeTOP></headeTOP>
   
    <router-view/>
  </div>
</template>
<script>
import headeTOP from '@/components/heade.vue'
export default {
  components:{headeTOP}
  
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}div,p,ul,li,dl,dd,dt,span,h1,h2,h3,h4,body{
  padding:0;
  margin:0;
  list-style: none;
}
@media (min-width: 1280px){
  .common-width{
    width:1140px;
    margin: auto;
  }
}
@media (max-width: 1000px){
  .common-width{
    width:900px;
    margin: auto;
  }
}
</style>
